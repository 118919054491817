<template>
  <secondary-page-layout :title="$t('confirmation')">
    <v-dialog/>
    <div class="" v-if="allVerified === 0">
      {{$t('allVerifiedDisabled')}}
      <button @click="back" class="primary-btn bottom-page-btn"> OK</button>
    </div>
    <div v-else>
      <div class="confirm-page form-group">
        <otp-verify v-model="code" :info="confirmInfo" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>

        <form-confirm-info :termsAgree="i_agree" :info="confirmInfo" @changeTermsAgree="changeTermsAgree"  :insurance_type="insuranceType"/>
      </div>

      <button @click="generateOtp" class="primary-btn bottom-page-btn" :disabled="!i_agree"> {{ $t('confirm') }} </button>
      <swipe-modal
          v-model="isModal"
          contents-height="50vh"
          border-top-radius="16px"
          :persistent="true"
          backgroundColor="#F7F7F7"
          tip-color="#c8c8c8"
          contents-color="#F7F7F7"
      >
        <sos-feedback></sos-feedback>
      </swipe-modal>
    </div>
  </secondary-page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormConfirmInfo from "../components/FormConfirmInfo";
import CreditCardSelect from "../components/BuyPolicy/CreditCardSelect";
import eventLogger from "../services/eventLogger";
import OtpVerify from '../components/OTP/OtpVerify';
import ePayPayment from "../mixin/ePayPayment";
import swipeModal from "@takuma-ru/vue-swipe-modal";
import SosFeedback from "./SosFeedback";

export default {
  name: "OgpoFormConfirm",
  components: {FormConfirmInfo, CreditCardSelect, OtpVerify, swipeModal,
    SosFeedback},
  mixins: [ePayPayment],
  computed:{
    ...mapGetters({
      calculatedSum: "ogpoForm/calculatedSum",
      startDate: "ogpoForm/beginDate",
      phoneNumber: "ogpoForm/phoneNumber",
      selectedProductId: "ogpoForm/selectedProductId",
      products: "ogpoForm/products",
      clients: "ogpoForm/clients",
      allVerified: 'ogpoForm/allVerified',
      otpKey: 'ogpoForm/otpKey',
      friendBasket: 'ogpoForm/friendBasket',
      invoiceId: 'ogpoForm/invoiceId',
      homebankToken: 'ogpoForm/homebankToken',
      email: 'ogpoForm/email',
      selectedProductName: "ogpoForm/selectedProductName",
      period: 'ogpoForm/period',
      beginDate: "ogpoForm/beginDate",
      cascoSelected: 'ogpoForm/cascoSelected'
    }),
    fixSum() {
      return this.calculatedSum?.fixedSum;
    },
    bonus() {
      const { selectedProductId, products } = this;

      if(!selectedProductId || products.length === 0) { return }

      return products.find((b) => b.mergeProduct.id === selectedProductId)
    },
    fullName() {
      if(!this.clients || this.clients.length === 0) { return }

      return this.clients[0].fullName;
    },
    availableBonusProduct() {
      const bonus =  this?.products?.find(product => product['mergeProduct'].id === 16)

      if(!bonus) {
        return
      }

      return bonus.mergeProduct
    },
    confirmInfo() {
      return {
        fullname: this.fullName,
        phone_number: this.phoneNumber,
        start_date: this.startDate,
        bonus_product: this.bonus,
        amount: this.fixSum,
        friendBasket: this.friendBasket,
        autoPlus: this.availableBonusProduct
      }
    }
  },
  data() {
    return {
      isModal:false,
      i_agree: true,
      insuranceType: 1000,
      card: null,
      errorMessage: null,
      code: '',
      calculationData: null
    }
  },
  async mounted() {
    if(this.allVerified === 0) {
      // this.$modal.show('dialog', {
      //   title: 'Уважаемый клиент',
      //   text: this.$i18n.t('allVerifiedDisabled'),
      //   buttons: [{
      //     title: 'OK',
      //     handler: () => {
      //       this.$router.push('/');
      //     }
      //   }]
      // }, {class: 'error'});
    }
    else{
      await this.generateOtp();
    }
  },
  watch: {
    otpKey() {
      this.saveDraft()
    }
  },
  methods:{
    ...mapActions({
      sendOTP: 'ogpoForm/sendOTP',
      verifyOTP: 'ogpoForm/verifyOTP',
      saveDraft: 'ogpoForm/saveWithoutDebounce'
    }),
    async generateOtp () {
      const loader = this.$loading.show({});

      try {
        const loggerPayload = {
          benefit_product: this.selectedProductName,
          period: this.period,
          kasko: this.cascoSelected,
          product_type: 'ogpo',
          payment_sum: this.calculatedSum.fixedSum,
          iin: this.clients.map(client => client.iin),
          phone_number: this.phoneNumber
        }

        await eventLogger('confirm_payment', loggerPayload)

        await this.sendOTP()
      } catch (e) {
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: this.$i18n.t('allVerifiedDisabled'),
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$router.push('/');
            }
          }]
        }, {class: 'error'});

        return loader.hide();
      } finally {
        loader.hide()
      }
    },
    back(){
      this.$router.push('/');
    },
    async sendConfirmCode() {
      try {
        await this.sendOTP();

        this.showVerify = true;
      } catch (e) {
        this.isModal = true
        throw e
      }
    },

    async verifyCode(code) {
      let loader = this.$loading.show();

      try {
        await this.verifyOTP(code)

        const { data } = await window.axios.post(`insurance-products/calculations/ogpo`, {
          invoiceId: this.invoiceId.toString(),
          amount: this.fixSum,
          holder: this.clients[0],
          phone_number: this.phoneNumber,
          beginDate: this.beginDate,
          calculation: this.$store.state['ogpoForm'].draftId
        })

        this.calculationData = data;
        console.log(this.calculationData)
        await this.saveDraft();

        await this.goToPaymentPage(this.calculationData, this.$store.getters['authUser'])

        // await this.ogpoPayment(data, this.invoiceId, this.fixSum, this.$store.getters['authUser'])
        // this.$refs.paymentForm.submit();
      } catch (err) {
        this.isModal = true
        if (err.code === 410) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.tryAmount");

        }
        if (err.code === 404) {
          this.errorMessage =  "Время действия кода подтверждения истекло";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.timeGone");

        }
        if (err.code === 400) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.invalidCode");

        }
        if (err.code === 403) {
          this.errorMessage = "Код более не валиден";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.codeGone");
        }
        if (err.code === 500) {
          this.errorMessage = "Не удалось верифицировать номер телефона";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.serviceError");
        }
      } finally {
        loader.hide()
      }
    },

    changeTermsAgree(val) {
      this.i_agree = val;
    },
  }
};
</script>
